<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-card title="Crie um grupo de clientes 🔍">
        <b-card-text>Preencha o formulário para gerar um novo grupo</b-card-text>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label for="group-name">Digite o nome do grupo</label>
              <b-form-input 
                icon-no-border  
                v-model="group.name" 
                class="input-rounded-full" 
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <label for="group-type">Selecione o tipo de grupo</label>
              <b-form-checkbox switch name="group-type" v-model="group.type" value="DYNAMIC" :unchecked-value="'static'" class="mt-1" size="md">Dinamico</b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <label for="group-type">Prazo de permanência</label>
              <b-form-select v-model="group.turn_over" size="lg">
                <b-form-select-option value="" disabled>Selecione</b-form-select-option>
                <b-form-select-option :value="'DAILY'">Dia</b-form-select-option>
                <b-form-select-option :value="'MONTHLY'">Mês</b-form-select-option>
                <b-form-select-option :value="'YEARLY'">Ano</b-form-select-option>
                <b-form-select-option :value="'INDETERMINATED'">Indeterminada</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-button class="act-button mt-2" @click="saveGroup" variant="gradient-primary">Cadastrar grupo</b-button>
          </b-col>

        </b-row>
        <alert-toast :message="this.warningMessage" :variant="this.messageVariant" ref="warning-toast"/>

      </b-card>
      <b-card v-if="group.type == 'DYNAMIC'"title="Selecione as regras automáticas do grupo">
        <b-col>
          <general-user-group-rules-list @removeRuleToGroup="removeRule" @addRuleToGroup="addRule" :searchedGroups="all_active_user_group_rules" :contextActions="'addToQuery'"/>
        </b-col>
      </b-card>
    </div>
    <div v-else>
      <logo-loader-animation/>
    </div>
  </div>
  
</template>

<script>
import { BCard,BRow, BButton,BCardText, BCol, BLink, BFormInput, BFormGroup, BFormSelect, BFormSelectOption, BFormCheckbox, BFormInvalidFeedback } from 'bootstrap-vue'
import gql from 'graphql-tag'
import {_} from 'vue-underscore';
import LogoLoaderAnimation from "@/components/misc/LogoLoaderAnimation.vue"
import AlertToast from "@/components/misc/AlertToast.vue"
import GeneralUserGroupRulesList from "@/components/groups/GeneralUserGroupRulesList.vue"

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormInvalidFeedback,
    underscore: _,
    BCol,
    AlertToast,
    LogoLoaderAnimation,
    BFormGroup, BFormCheckbox,BRow,
    BFormSelect, BFormSelectOption,
    GeneralUserGroupRulesList

  },
  data () {
    return {
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
      queryResult:null,
      group:{type:'STATIC'},
      groupRules:[],
      warningMessage:null,
      messageVariant:null,
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2 ? true : false
    }
  },
  apollo:{
    all_active_user_group_rules: {
      query: gql`query{
        all_active_user_group_rules(status:"ACTIVE"){
          id,
          name,
          aggregator,
          db_reference,
          operator,
          value,
          status,
          created_at
        }
      }`,
      client: 'identityClient'
    },
  },
  methods:{
    addRule(id){
      this.groupRules.push(id);
    },
    removeRule(id){
      this.groupRules = this.groupRules.filter(r => r != id);
    },
    saveGroup(){
      if(this.group.name == null || this.group.turn_over == null){
        this.warningMessage = "Preencha todos os dados!";
        this.messageVariant = "warning";
        this.$refs['warning-toast'].showAlert();
      }else if(this.group.type == 'DYNAMIC' && this.groupRules.length < 1){
        this.warningMessage = "Grupo do tipo dinâmico precisa de ao menos uma regra selecionada!";
        this.messageVariant = "warning";
        this.$refs['warning-toast'].showAlert();
      }else{
        this.executeGroupSave();
      }
    },
    executeGroupSave(){
      this.$apollo.mutate({
        mutation: gql`mutation($group_name: String,$group_type: String,$group_turn_over: String){
          createUserGroup(
            name : $group_name,
            type : $group_type,
            status : "ACTIVE",
            turn_over : $group_turn_over,
            last_change_by:"Agent ag"
          ){
            id
          }
        }`,
        variables:{
          group_name: this.group.name,
          group_type: this.group.status,
          group_turn_over:this.group.turn_over,
        },
        client: "identityClient"
      }).then((data) => {
        this.warningMessage = 'Grupo adicionado com sucesso!';
        this.messageVariant = 'success';
        this.$refs['warning-toast'].showAlert();
        if(this.group.type == 'DYNAMIC'){
          this.savedGroupId = data.data.createUserGroup.id;
          this.saveGroupRules();
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    saveGroupRules(){
      _.each(this.groupRules, this.saveGroupRule);
    },
    saveGroupRule(e){
      this.tmpRule = e;
      this.$apollo.mutate({
        mutation: gql`mutation($group_id: ID,$rule_id: ID){
          createUserGroupRulesRelationship(
            user_group_id : $group_id,
            user_group_rule_id : $rule_id,
          ){
            id
          }
        }`,
        variables:{
          group_id: this.savedGroupId,
          rule_id: this.tmpRule,
        },
        client: "identityClient"
      }).then((data) => {
        this.warningMessage = 'Regra adicionada com sucesso!';
        this.messageVariant = 'success';
        this.$refs['warning-toast'].showAlert();
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  created(){
    // this.searchUser()
  }
}
</script>

<style>

</style>
